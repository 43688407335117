<template>
  <auth>
    <template slotscope="defaultSlotScope">
      <a-row
        type="flex"
        justify="space-around"
        align="middle"
        style="height :100vh ;"
        class="ant-row-flex bg"
      >
        <a-col :lg="8" :xl="7"  :md="12" :sm="16" :xs="22">
          <a-alert
            v-if="errors"
            type="error"
            message="Email n'est pas valide"
            style="margin-bottom: 30px;"
            banner
          />
          <h2 v-if="success" class="height-100 success_msg">
            Nous vous avons envoyé un lien Veuillez vérifier votre boîte de mail
            et cliquez sur le lien de confirmation.
          </h2>
          <a-form
            v-else
            @submit="handleSubmit"
            :form="form"
            :layout="vertical"
            style="background-color: #ffffffa1;padding: 5%;"
          >
            <h1 style="text-align : center">Récuperation mot de passe</h1>
            <a-form-item v-bind="formItemLayout">
              <a-input
                placeholder="Email"
                v-decorator="[
                  'email',
                  {
                    rules: [
                      { required: true, message: 'Email est obligatoire!' },
                      {
                        pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                        message: 'Le format de l\'email n\'est pas valide ',
                      },
                    ],
                  },
                ]"
              >
                <a-icon
                  slot="prefix"
                  type="mail"
                  style="color: rgba(0,0,0,.25)"
                />
              </a-input>
            </a-form-item>
            <a-form-item
              v-bind="tailFormItemLayout"
              style="margin-bottom : 10px"
            >
              <a-spin :spinning="spinning">
                <a-button
                  type="primary"
                  class="regiter-form-button"
                  htmlType="submit"
                  @click="handleSubmit"
                  >Envoyer</a-button
                >
              </a-spin>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </template>
  </auth>
</template>

<script>
// import { mapActions, mapMutations, mapState } from "vuex";
import { mapActions, mapMutations } from "vuex";
import Auth from "@/components/common/AuthParent";
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
};
const formItemLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};
export default {
  name: "ForgotPassword",
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  components: {
    Auth,
  },
  created() {
    this.logout();
  },
  data() {
    return {
      errors: false,
      success: false,
      spinning: false,
      autoCompleteResult: [],
      formItemLayout,
      tailFormItemLayout,
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.spinning = true;
          this.forgot({ email: values.email, dtn: "2000-01-01" })
            .then(() => {
              this.$_showSuccessMessage("Email envoyé avec success", 10);
              this.success = true;
              this.spinning = false;
            })
            .catch((err) => {
              this.spinning = false;
              if (err.response.status === 404) {
                this.$_throwAnError("Votre email est introuvable  !!", 10);
              }
            });
        }
      });
    },
    displayErrors(state) {
      this.errors = state;
    },
    ...mapActions({ forgot: "forgotPassword" }),
    ...mapMutations({ logout: "clearAuthentication" }),
  },
};
</script>

<style scoped>
.regiter-form-button {
  width: 100%;
}
.success_msg {
  background-color: #ffffffb5;
  margin: auto;
  padding: 5%;
  color: #009688;
}
.c-footer {
  background: #009688;
}
</style>
